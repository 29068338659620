<template>
  <a-layout class='index animated fadeIn s_bf'>
    <a-tabs @change="callback" v-model:activeKey="activeKey">
      <a-tab-pane :key="0" tab="审核成功">
      </a-tab-pane>
      <a-tab-pane :key="1" tab="审核被拒绝">
      </a-tab-pane>
      <a-tab-pane :key="2" tab="审核中">
      </a-tab-pane>
      <a-tab-pane :key="3" tab="已撤回">
      </a-tab-pane>
      <a-tab-pane :key="4" tab="审核延后">
      </a-tab-pane>
    </a-tabs>
    <a-table :pagination='false' :loading='listLoading' bordered :columns='columns' :data-source='data'
      :row-selection="rowSelection">
      <template v-slot:reason="{ record }">
        <div v-html='record.reason'></div>
      </template>
      <template v-slot:action="{ record }">
        <a-dropdown>
          <a class="ant-dropdown-link"> 操作
            <DownOutlined />
          </a>
          <template v-slot:overlay>
            <a-menu>
              <a-menu-item>
                <a v-has:appletCodeReviewDetail="()=>$router.push({path:'/appletCode/appletDetail/index',query:{brand_id:record.brand_id,app_id:record.app_id}})"
                  href="javascript:;">详情</a>
              </a-menu-item>
              <a-menu-item v-if="activeKey==0">
                <a v-has:appletCodePut="()=>appletput(record)" href="javascript:;">发布</a>
              </a-menu-item>
              <a-menu-item v-if="activeKey==2">
                <a v-has:auditrecall="()=>auditrecall(record)" href="javascript:;">撤销审核</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
    <div class="s_flex_bian_c m-t-20">
      <div>
        <a-button type="link" v-has:appletCodePutAll="()=>appletMorePut()" v-if="activeKey==0">
          批量发布
        </a-button>
        <a-button type="link" v-has:appletCodePutOne="()=>allAppletPut()" v-if="activeKey==0">
          一键发布
        </a-button>
      </div>
      <a-pagination :style="{textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page"
        :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange"
        :show-total="total => `总数: ${total} `" />
    </div>
  </a-layout>
  <a-modal v-model:visible="visible" title="发布" @ok="handleOk" centered :confirm-loading="confirmLoading" destroyOnClose
    :width="1000" :height="400">
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns1' :data-source='data'>
    </a-table>
    <p class="mb10" style="color:#f04635">是否要确定批量重新审核失败的小程序么？</p>
  </a-modal>
</template>
<script>
  import { DownOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { getRouter } from "@/utils/app";
  import { setPageState, getPageState } from "@/utils/pageState";
  import { getsubmitAuditAll, getMinidraftList, delMiniapp, getAuditDetail, miniCodePutWhole, submitAuditrecall } from "@/api/appletCode";
  import { $iscode } from "@/utils/app";
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 10,
    is_release: 0,
    audit_status: 0,
    status: 1
  };
  export default {
    components: { DownOutlined },
    setup(props, ctx) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        total: 1,
        active: 1,
        visible: false,
        activeKey: 0,
        columns: [{
          title: "版本号",
          dataIndex: "user_version",
          key: "user_version",
          width: 120,
        },
        {
          title: "APPID",
          dataIndex: "app_id",
          key: "app_id",
        },
        {
          title: "品牌商名称",
          dataIndex: "brand_name",
          key: "brand_name",
        },
        {
          title: "版本描述",
          dataIndex: "user_desc",
          key: "user_desc",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 100,
          slots: { customRender: "action" },
        },]
      });
      const $router = inject("$router");
      const $Modal = inject("$Modal");
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, limit) => {
        search.value.page = 1;
        search.value.limit = limit;
        initData(search.value);
      };
      // 小程序模板添加至草稿箱
      const agentDraftToTempalte = async (e) => {
        let res = await addTemplate({ draft_id: e.draft_id }).then((res) => res.data);
        $iscode(res, true)
      };
      //小程序列表
      const initData = async (values) => {
        state.listLoading = true;
        let res = await getAuditDetail(values).then((res) => res.data);;
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        }

      };
      onMounted(() => {
        initData(search.value);
      });
      // 删除
      const appletDel = (data) => {
        $Modal.confirm({
          content: '确定要删除该模板么',
          centered:true, onOk: async () => {
            let res = await delMiniapp({ template_id: data.template_id }).then(res => res.data);
            if ($iscode(res, true)) {
              initData(search.value);
            }
          }
        })
      }
      // 添加模板至草稿箱
      const addTemplate = (data) => {
        $Modal.confirm({
          content: '确定要删除该模板么',
          centered:true, onOk: async () => {
            let res = await delMiniapp({ template_id: data.template_id }).then(res => res.data);
            if ($iscode(res, true)) {
              initData(search.value);
            }
          }
        })
      }
      const selecteds = ref([])
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          selecteds.value = selectedRows
        }
      };
      const callback = (e) => {
        search.value.audit_status = e
        if (e == 1) {
          state.columns = [
            {
              title: "版本号",
              dataIndex: "user_version",
              key: "user_version",
              width: 120,
            },
            {
              title: "版本描述",
              dataIndex: "user_desc",
              key: "user_desc",
              width:300
            },
            {
              title: "品牌商名称",
              dataIndex: "brand_name",
              key: "brand_name",
              width:250
            },
            {
              title: "失败原因",
              slots: { customRender: "reason" },
            },
            {
              title: "创建时间",
              dataIndex: "create_time",
              key: "create_time",
            },
            {
              title: "操作",
              key: "action",
              fixed: "right",
              width: 100,
              slots: { customRender: "action" },
            },
          ]
        } else {
          state.columns = [
            {
              title: "版本号",
              dataIndex: "user_version",
              key: "user_version",
              width: 120,
            },
            {
              title: "APPID",
              dataIndex: "app_id",
              key: "app_id",
            },
            {
              title: "品牌商名称",
              dataIndex: "brand_name",
              key: "brand_name",
              width:250
            },
            {
              title: "版本描述",
              dataIndex: "user_desc",
              key: "user_desc",
              width:300
            },
            {
              title: "创建时间",
              dataIndex: "create_time",
              key: "create_time",
            },
            {
              title: "操作",
              key: "action",
              fixed: "right",
              width: 100,
              slots: { customRender: "action" },
            },
          ]
        }
        initData(search.value);
      }
      const appletput = async (data) => {
        const hide = message.loading("正在加载中...", 0);
        let result = await miniCodePutWhole({ audit_ids: JSON.stringify([data.id]) }).then(res => res.data).catch(error => error)
        setTimeout(hide);
        $iscode(result, true)
      }
      const appletMorePut = async () => {
        if (!selecteds.value.length) {
          message.error('请选择要发布的代码');
          return
        }
        const hide = message.loading("正在加载中...", 0);
        let result = await miniCodePutWhole({ audit_ids: JSON.stringify(selecteds.value.map(v => v.id)) }).then(res => res.data).catch(error => error)
        setTimeout(hide);
        $iscode(result, true)
      }
      const allAppletPut = async () => {
        const hide = message.loading("正在加载中...", 0);
        let result = await miniCodePutWhole({ audit_ids: JSON.stringify([]) }).then(res => res.data).catch(error => error)
        setTimeout(hide);
        $iscode(result, true)
      }
      const auditrecall = (data) => {
        $Modal.confirm({
          content: '确定要撤销本次审核提交么',
          centered:true,
          onOk: async () => {
            let result = await submitAuditrecall({ audit_id: data.id }).then(res => res.data).catch(error => error)
            if ($iscode(result, true)) {
              initData(search.value);
            }
          }
        })
      }
      return {
        ...toRefs(state),
        search,
        data,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        addTemplate,
        agentDraftToTempalte,
        appletDel,
        rowSelection,
        callback,
        appletput,
        allAppletPut,
        auditrecall,
        appletMorePut
      };
    },
  };
</script>