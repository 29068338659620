<template>
  <a-tabs @change="detailCallback">
    <a-tab-pane :key="1" tab="成功">
    </a-tab-pane>
    <a-tab-pane :key="0" tab="失败">
    </a-tab-pane>
  </a-tabs>
  <a-table :pagination='false' :loading='listLoading' :columns='columns1' :data-source='detailData'>
    <template v-slot:action="{ record }">
      <a-button v-has:appletCodeReview="()=>{examineShow=true;listItem=record}" type="link">一键审核</a-button>
    </template>
  </a-table>
  <a-modal v-model:visible="examineShow" title="提交审核" @ok="appletCodeReview" centered
    :confirm-loading="confirmLoading" destroyOnClose :width="500">
    <div class="s_flex_bian m-b-20">
      <p>小程序类型：</p>
      <div class="width80">
        <a-radio-group v-model:value="miniapp_type">
          <a-radio :value="1"> 云店小程序 </a-radio>
          <a-radio :value="2"> 会员小程序 </a-radio>
          <a-radio :value="3"> 渠道社区小程序 </a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="s_flex_bian m-b-20">
      <p>反馈内容：</p>
      <div class="width80">
        <a-textarea v-model:value="feedback_info" placeholder="请输入" :rows="4" />
      </div>
    </div>
    <div class="s_flex_bian">
      <p>操作截屏：</p>
      <div class="width80">
        <a-upload v-model:fileList="fileListImg" name="file" :data="{brand_id:listItem.brand_id}" list-type="picture-card" class="avatar-uploader"
        :action="baseUrl+'v2/miniapp/auditUpload'" multiple :headers='header' @change="handleChangeImg">
        <div v-if='fileListImg.length == 0'>
          <PlusOutlined />
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
      </div>
    </div>
    <div class="s_flex_bian">
      <p>操作录屏：</p>
      <div class="width80">
        <a-upload v-model:fileList="fileListVideo" name="file" :data="{brand_id:listItem.brand_id}" list-type="picture-card" class="avatar-uploader"
        :action="baseUrl+'v2/miniapp/auditUpload'" :headers='header' @change="handleChangeVideo">
        <div v-if='fileListVideo.length == 0'>
          <PlusOutlined />
          <div class="ant-upload-text">上传视频</div>
        </div>
      </a-upload>
      </div>
    </div>
  </a-modal>
</template>
<script>
  import { PlusOutlined } from '@ant-design/icons-vue'
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { submitAuditwhole, getMiniappBatchDetail } from "@/api/appletCode";
  import { $iscode } from "@/utils/app";
  import { getToken } from "@/libs/util";
  import config from "@/config";
  import store from "@/store";
  const baseUrl = config.baseUrl[process.env.VUE_APP_type];
  const header = { apiAuth: getToken() };
  export default {
    components: {PlusOutlined},
    props: {
      id: {
        type: Number
      },
    },
    setup(props, { emit }) {
      // // 初始化 分页信息和筛选项信息
      // 列表数据和列头配置
      const state = reactive({
        fileListImg: [],
        fileListVideo: [],
        listLoading: false,
        confirmLoading: false,
        miniapp_type: 1,
        listItem: {},
        examineShow: false,
        feedback_info: "用户通过点击首页操作按钮调起扫一扫功能，扫描商品二维码，进入商品溯源页面，进行商品防伪溯源追踪",
        video_id_list: [],
        pic_id_list:[]
      });
      let columns1 = [
        {
          title: "版本号",
          dataIndex: "user_version",
          key: "user_version",
        },
        {
          title: "版本描述",
          dataIndex: "user_desc",
          key: "user_desc",
          width: 300
        },
        {
          title: "appID",
          dataIndex: "app_id",
          key: "app_id",
        },
        {
          title: "品牌商编号",
          dataIndex: "brand_id",
          key: "brand_id",
        },
        {
          title: "品牌商名称",
          dataIndex: "brand_name",
          key: "brand_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 100,
          slots: { customRender: "action" },
        },
      ];
      const $router = inject("$router");
      const $Modal = inject("$Modal");
      onMounted(() => {
        appletReleaseCodeDetail();
      });
      const handleChangeImg = (info) => {
        if (info.file.status === 'done') {
          state.fileListImg = info.fileList.splice(info.fileList.length - 1, 1)
          state.pic_id_list = [info.file.response.data.mediaid]
        }
        if (info.file.status === 'error') {
          message.error('上传失败！');
        }
        if (info.file.status === 'removed') {
          state.pic_id_list = []
        }
      }
      const handleChangeVideo = (info) => {
        if (info.file.status === 'done') {
          state.fileListVideo = info.fileList.splice(info.fileList.length - 1, 1)
          state.video_id_list = [info.file.response.data.mediaid]
        }
        if (info.file.status === 'error') {
          message.error('上传失败！');
        }
        if (info.file.status === 'removed') {
          state.video_id_list = []
        }
      }
      // 审核
      const appletCodeReview = async () => {
        const hide = message.loading("正在加载中...", 0);
        state.confirmLoading = true;
        let result = await submitAuditwhole({ code_group_id: state.listItem.group_id,app_id:state.listItem.app_id, miniapp_type: state.miniapp_type, feedback_info: state.feedback_info, video_id_list:state.video_id_list ,pic_id_list:state.pic_id_list}).then(res => res.data).catch(error => error)
        setTimeout(hide);
        state.confirmLoading = false;
        if($iscode(result)){
          emit("review");
          state.examineShow = false;
        }else{
          message.error(result.msg);
        }
      };
      const detailSearch = ref({
        page: 1,
        limit: 1000,
        status: 1
      })
      const detailData = ref()
      const appletReleaseCodeDetail = async () => {
        let res = await getMiniappBatchDetail({ ...detailSearch.value, group_id: props.id }).then((res) => res.data);
        detailData.value = res.data.data;
      };
      const detailCallback = (e) => {
        detailSearch.value.status = e
        detailSearch.value.page = 1
        appletReleaseCodeDetail()
      }
      return {
        ...toRefs(state),
        appletCodeReview,
        columns1,
        appletReleaseCodeDetail,
        detailData,
        detailCallback,
        handleChangeImg,
        handleChangeVideo,
        baseUrl,
        header,
      };
    },
  };
</script>