<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <a-tabs @change="callback" v-model:activeKey="activeKey">
        <a-tab-pane :key="1" tab="代码同步记录">
          <a-table
            :pagination="false"
            :loading="listLoading"
            bordered
            rowKey="id"
            :columns="columns"
            :data-source="data"
          >
            <template v-slot:action="{ record }">
              <a-dropdown v-if="activeKey == 1">
                <a class="ant-dropdown-link">
                  操作
                  <DownOutlined />
                </a>
                <template v-slot:overlay>
                  <a-menu>
                    <a-menu-item>
                      <a
                        v-has:synchroRecordDetails="
                          () => {
                            detailVisible = true;
                            listItem = record;
                          }
                        "
                        href="javascript:;"
                        >详情</a
                      >
                    </a-menu-item>
                    <a-menu-item>
                      <a
                        v-has:appletCodeReview="
                          () => {
                            examineShow = true;
                            listItem = record;
                          }
                        "
                        href="javascript:;"
                        >一键审核</a
                      >
                    </a-menu-item>
                    <a-menu-item>
                      <a
                        v-has:appletCodeReviewFast="
                          () => fastAppletCodeReview(record)
                        "
                        href="javascript:;"
                        >加急审核</a
                      >
                    </a-menu-item>
                    <!-- <a-menu-item v-if="record.status!=1">
                          <a v-has:appletCodeReview="()=>appletCodeReview(record)" href="javascript:;">重新提交</a>
                        </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-table>
          <a-pagination
            :style="{ marginTop: '20px', textAlign: 'right' }"
            show-size-changer
            show-quick-jumper
            :current="search.page"
            :page-size="search.limit"
            :total="total"
            @change="pageCurrentChange"
            @showSizeChange="pageSizeChange"
            :show-total="(total) => `总数: ${total} `"
          />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="代码审核记录">
          <appletRelease />
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </a-layout>
  <a-modal
    v-model:visible="detailVisible"
    title="详情"
    centered
    destroyOnClose
    :width="1200"
    :bodyStyle="{ height: '80vh', overflowY: 'scroll' }"
  >
    <logDetail :id="listItem.id" @review="detailVisible = false"></logDetail>
  </a-modal>
  <a-modal
    v-model:visible="examineShow"
    title="请选择审核小程序类型"
    @ok="appletCodeReview"
    centered
    :confirm-loading="confirmLoading"
    destroyOnClose
    :width="500"
  >
    <a-radio-group v-model:value="miniapp_type">
      <!-- <a-radio :value="1"> 云店小程序 </a-radio>
      <a-radio :value="2"> 会员小程序 </a-radio>
      <a-radio :value="3"> 渠道社区小程序 </a-radio> -->
      <a-radio :value="101"> 业务小程序 </a-radio>
      <a-radio :value="102"> 种植户小程序 </a-radio>
    </a-radio-group>
  </a-modal>
</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
import appletRelease from "./components/appletRelease";
import logDetail from "./components/logDetail";
import {
  getMiniappBatchIndex,
  getsubmitAuditAll,
  getMiniappBatchDetail,
  submitAuditSpeed,
  submitAuditwhole
} from "@/api/appletCode";
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10
};
export default {
  components: { DownOutlined, appletRelease, logDetail },
  setup(props, ctx) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      total: 1,
      active: 1,
      activeKey: 1,
      detailVisible: false,
      confirmLoading: false,
      miniapp_type: 1,
      listItem: {},
      examineShow: false
    });
    let columns = [
      {
        title: "版本号",
        dataIndex: "user_version",
        key: "user_version",
        width: 150
      },
      {
        title: "版本描述",
        dataIndex: "user_desc",
        key: "user_desc"
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "status_text"
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
        width: 200
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: 100,
        slots: { customRender: "action" }
      }
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    //小程序列表
    const initData = async (values) => {
      state.listLoading = true;
      let res = {};
      if (state.activeKey == 1) {
        res = await getMiniappBatchIndex(values).then((res) => res.data);
      } else {
        res = await getsubmitAuditAll(values).then((res) => res.data);
      }
      state.listLoading = false;
      if ($iscode(res)) {
        data.value = res.data.data;
        state.total = res.data.total;
      }
    };
    onMounted(() => {
      initData(search.value);
    });
    // 审核
    const appletCodeReview = async () => {
      const hide = message.loading("正在加载中...", 0);
      state.confirmLoading = true;
      // state.visible = true;
      let result = await submitAuditwhole({
        code_group_id: state.listItem.id,
        miniapp_type: state.miniapp_type
      })
        .then((res) => res.data)
        .catch((error) => error);
      setTimeout(hide);
      state.confirmLoading = false;
      state.examineShow = false;
      $iscode(result, true);
    };
    const callback = () => {
      search.value.page = 1;
      initData(search.value);
    };
    const fastAppletCodeReview = async (e) => {
      $Modal.confirm({
        content: "确定加急审核么?一个小程序一年只有三次加急机会",
        centered: true,
        onOk: async () => {
          let result = await submitAuditSpeed({ id: e.id })
            .then((res) => res.data)
            .catch((error) => error);
          $iscode(result, true);
        }
      });
    };
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      appletCodeReview,
      callback,
      fastAppletCodeReview
    };
  }
};
</script>
